import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter as Routers } from 'react-router-dom';
import MainLayout from '../Layout/MainLayout';
import PageLoding from '../components/PageLoding';

const Home = React.lazy(() => import('../view/index'));
const IdoIndex = React.lazy(() => import('../view/ido'));
const IdoBuyDetail = React.lazy(() => import('../view/ido/buyDetail'));
const My = React.lazy(() => import('../view/my/index'));
const MyWhiteList = React.lazy(() => import('../view/my/whiteList/index'));

export default function Router() {
    return (
        <Suspense fallback={<PageLoding></PageLoding>}>
            <Routes>
                <Route path="/*" element={<MainLayout />}>
                    {/* 首页 */}
                    <Route path="" element={<Home />} />
                    <Route path="ido" element={<IdoIndex />} />
                    <Route path="ido/buyDetail" element={<IdoBuyDetail />} />
                    <Route path="my" element={<My />} />
                    <Route path="my/whiteList" element={<MyWhiteList />} />
                </Route>
            </Routes>
        </Suspense>
    );
}
